var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.hasAccess
        ? _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "text-h5 mb-2 pb-0" },
                [
                  _vm._v("\n      " + _vm._s(_vm.displayName) + "\n      "),
                  _c("v-spacer"),
                  _vm.infoDescription
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { top: "", "z-index": "15" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-icon",
                                      _vm._g({ attrs: { color: "info" } }, on),
                                      [
                                        _vm._v(
                                          "\n            mdi-information\n          "
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1838283674
                          )
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.infoDescription))])]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c("v-card-text", [
                _vm._v(
                  '\n      You do not have "Reporting - KPI Widgets permissions"\n    '
                )
              ])
            ],
            1
          )
        : _vm.dates.length === 2 && !_vm.resultsLoading
        ? _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "text-h5 mb-2 pb-0" },
                [
                  _vm._v("\n      " + _vm._s(_vm.displayName) + "\n      "),
                  _c("v-spacer"),
                  _vm.infoDescription
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { "z-index": "15", top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-icon",
                                      _vm._g({ attrs: { color: "info" } }, on),
                                      [
                                        _vm._v(
                                          "\n            mdi-information\n          "
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1838283674
                          )
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.infoDescription))])]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "pb-0" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "pr-0", attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-progress-circular",
                            {
                              attrs: {
                                rotate: -90,
                                size: 100,
                                width: 15,
                                value: _vm.results.metricRateTotals,
                                color: "#98EB108A"
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                { attrs: { size: "52", color: "black" } },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.icon) +
                                      "\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-list",
                            [
                              _c(
                                "v-list-item",
                                [
                                  _c("v-list-item-title"),
                                  _c(
                                    "v-list-item-title",
                                    {
                                      staticClass: "text-wrap",
                                      style: [
                                        {
                                          display: "flex",
                                          "justify-content": "center",
                                          "font-weight": "bold"
                                        }
                                      ]
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.dateRangeText(_vm.dates)) +
                                          "\n              "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-list-item-title",
                                    {
                                      style: [
                                        {
                                          display: "flex",
                                          "justify-content": "center",
                                          "font-weight": "bold"
                                        }
                                      ]
                                    },
                                    [
                                      _vm._v(
                                        "\n                VS\n              "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-list-item-title",
                                    {
                                      staticClass: "text-wrap",
                                      style: [
                                        {
                                          display: "flex",
                                          "justify-content": "center",
                                          "font-weight": "bold"
                                        }
                                      ]
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.dateRangeText(
                                              _vm.comparisonDates
                                            )
                                          ) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-list-item",
                                [
                                  _c("v-list-item-title"),
                                  !_vm.hideCounts
                                    ? _c(
                                        "v-list-item-title",
                                        {
                                          style: [
                                            {
                                              display: "flex",
                                              "justify-content": "center"
                                            }
                                          ]
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm._f("commaNumber")(
                                                  _vm.results.metricTotals
                                                )
                                              ) +
                                              " (" +
                                              _vm._s(
                                                _vm.results.metricRateTotals
                                              ) +
                                              " " +
                                              _vm._s(_vm.appendedString) +
                                              ")\n              "
                                          )
                                        ]
                                      )
                                    : _vm.isSeconds
                                    ? _c(
                                        "v-list-item-title",
                                        {
                                          style: [
                                            {
                                              display: "flex",
                                              "justify-content": "center"
                                            }
                                          ]
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.results.metricRateTotals
                                              ) +
                                              _vm._s(_vm.appendedString) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    : _c(
                                        "v-list-item-title",
                                        {
                                          style: [
                                            {
                                              display: "flex",
                                              "justify-content": "center"
                                            }
                                          ]
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.results.metricRateTotals
                                              ) +
                                              _vm._s(_vm.appendedString) +
                                              "\n              "
                                          )
                                        ]
                                      ),
                                  _c(
                                    "v-list-item-title",
                                    {
                                      style: [
                                        {
                                          display: "flex",
                                          "justify-content": "center"
                                        }
                                      ]
                                    },
                                    [
                                      !_vm.hideCounts
                                        ? [
                                            _vm.getDifference(
                                              _vm.results.metricTotals,
                                              _vm.resultsCompare.metricTotals
                                            ) > 0
                                              ? _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      size: "medium",
                                                      color: "green"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    mdi-arrow-up-bold-circle-outline\n                  "
                                                    )
                                                  ]
                                                )
                                              : _vm.getDifference(
                                                  _vm.results.metricTotals,
                                                  _vm.resultsCompare
                                                    .metricTotals
                                                ) == 0
                                              ? _c("div")
                                              : _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      size: "medium",
                                                      color: "error"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    mdi-arrow-down-bold-circle-outline\n                  "
                                                    )
                                                  ]
                                                )
                                          ]
                                        : [
                                            _vm.getDifference(
                                              _vm.results.metricRateTotals,
                                              _vm.resultsCompare
                                                .metricRateTotals
                                            ) > 0
                                              ? _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      size: "medium",
                                                      color: "green"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    mdi-arrow-up-bold-circle-outline\n                  "
                                                    )
                                                  ]
                                                )
                                              : _vm.getDifference(
                                                  _vm.results.metricRateTotals,
                                                  _vm.resultsCompare
                                                    .metricRateTotals
                                                ) == 0
                                              ? _c("div")
                                              : _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      size: "medium",
                                                      color: "error"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    mdi-arrow-down-bold-circle-outline\n                  "
                                                    )
                                                  ]
                                                )
                                          ],
                                      [
                                        !_vm.hideCounts
                                          ? _c("span", [
                                              _c(
                                                "span",
                                                {
                                                  style:
                                                    "color: " +
                                                    _vm.getFontColor(
                                                      _vm.results.metricTotals,
                                                      _vm.resultsCompare
                                                        .metricTotals
                                                    )
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f("commaNumber")(
                                                          "" +
                                                            _vm.getAbsDifference(
                                                              _vm.results
                                                                .metricTotals,
                                                              _vm.resultsCompare
                                                                .metricTotals
                                                            )
                                                        )
                                                      )
                                                  )
                                                ]
                                              )
                                            ])
                                          : _vm.isSeconds
                                          ? _c("span", [
                                              _c(
                                                "span",
                                                {
                                                  style:
                                                    "color: " +
                                                    _vm.getFontColor(
                                                      _vm.results
                                                        .metricRateTotals,
                                                      _vm.resultsCompare
                                                        .metricRateTotals
                                                    )
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        "" +
                                                          _vm.getAbsDifference(
                                                            _vm.results
                                                              .metricRateTotals,
                                                            _vm.resultsCompare
                                                              .metricRateTotals
                                                          )
                                                      ) +
                                                      _vm._s(_vm.appendedString)
                                                  )
                                                ]
                                              )
                                            ])
                                          : _c("span", [
                                              _c(
                                                "span",
                                                {
                                                  style:
                                                    "color: " +
                                                    _vm.getFontColor(
                                                      _vm.results
                                                        .metricRateTotals,
                                                      _vm.resultsCompare
                                                        .metricRateTotals
                                                    )
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        "" +
                                                          _vm.getAbsDifference(
                                                            _vm.results
                                                              .metricRateTotals,
                                                            _vm.resultsCompare
                                                              .metricRateTotals
                                                          )
                                                      ) +
                                                      _vm._s(_vm.appendedString)
                                                  )
                                                ]
                                              )
                                            ])
                                      ]
                                    ],
                                    2
                                  ),
                                  [
                                    !_vm.hideCounts
                                      ? _c(
                                          "v-list-item-title",
                                          {
                                            style: [
                                              {
                                                display: "flex",
                                                "justify-content": "center"
                                              }
                                            ]
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm._f("commaNumber")(
                                                    _vm.resultsCompare
                                                      .metricTotals
                                                  )
                                                ) +
                                                "\n                "
                                            )
                                          ]
                                        )
                                      : _vm.isSeconds
                                      ? _c(
                                          "v-list-item-title",
                                          {
                                            style: [
                                              {
                                                display: "flex",
                                                "justify-content": "center"
                                              }
                                            ]
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.resultsCompare
                                                    .metricRateTotals
                                                ) +
                                                _vm._s(_vm.appendedString) +
                                                "\n                "
                                            )
                                          ]
                                        )
                                      : _c(
                                          "v-list-item-title",
                                          {
                                            style: [
                                              {
                                                display: "flex",
                                                "justify-content": "center"
                                              }
                                            ]
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.resultsCompare
                                                    .metricRateTotals
                                                ) +
                                                _vm._s(_vm.appendedString) +
                                                "\n                "
                                            )
                                          ]
                                        )
                                  ]
                                ],
                                2
                              ),
                              _vm.iconTwo && _vm.infoDescriptionTwo
                                ? _c(
                                    "v-list-item",
                                    [
                                      _c(
                                        "v-list-item-subtitle",
                                        {
                                          style: [{ "justify-content": "left" }]
                                        },
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: {
                                                top: "",
                                                "z-index": "15"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            {
                                                              attrs: {
                                                                left: "",
                                                                size: "30"
                                                              }
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              "\n                      " +
                                                                _vm._s(
                                                                  _vm.iconTwo
                                                                ) +
                                                                "\n                    "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                3050711721
                                              )
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(_vm.infoDescriptionTwo)
                                                )
                                              ])
                                            ]
                                          ),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.nameDescriptionTwo)
                                            )
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item-subtitle",
                                        {
                                          style: [
                                            {
                                              display: "flex",
                                              "justify-content": "center"
                                            }
                                          ]
                                        },
                                        [
                                          !_vm.hideCounts
                                            ? [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm._f("commaNumber")(
                                                        _vm.results
                                                          .metricInitial
                                                      )
                                                    ) +
                                                    " (" +
                                                    _vm._s(
                                                      _vm.results
                                                        .metricRateInitial
                                                    ) +
                                                    _vm._s(_vm.appendedString) +
                                                    ")\n                "
                                                )
                                              ]
                                            : _vm.isSeconds
                                            ? [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm.results
                                                        .metricRateInitial
                                                    ) +
                                                    _vm._s(_vm.appendedString) +
                                                    "\n                "
                                                )
                                              ]
                                            : [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm.results
                                                        .metricRateInitial
                                                    ) +
                                                    _vm._s(_vm.appendedString) +
                                                    "\n                "
                                                )
                                              ]
                                        ],
                                        2
                                      ),
                                      _c(
                                        "v-list-item-subtitle",
                                        {
                                          style: [
                                            {
                                              display: "flex",
                                              "justify-content": "center"
                                            }
                                          ]
                                        },
                                        [
                                          !_vm.hideCounts
                                            ? [
                                                _vm.getDifference(
                                                  _vm.results.metricInitial,
                                                  _vm.resultsCompare
                                                    .metricInitial
                                                ) > 0
                                                  ? _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "pb-1",
                                                        attrs: {
                                                          size: "medium",
                                                          color: "green"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    mdi-arrow-up-bold-circle-outline\n                  "
                                                        )
                                                      ]
                                                    )
                                                  : _vm.getDifference(
                                                      _vm.results.metricInitial,
                                                      _vm.resultsCompare
                                                        .metricInitial
                                                    ) == 0
                                                  ? _c("div")
                                                  : _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "pb-1",
                                                        attrs: {
                                                          size: "medium",
                                                          color: "error"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    mdi-arrow-down-bold-circle-outline\n                  "
                                                        )
                                                      ]
                                                    )
                                              ]
                                            : [
                                                _vm.getDifference(
                                                  _vm.results.metricRateInitial,
                                                  _vm.resultsCompare
                                                    .metricRateInitial
                                                ) > 0
                                                  ? _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "pb-1",
                                                        attrs: {
                                                          size: "medium",
                                                          color: "green"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    mdi-arrow-up-bold-circle-outline\n                  "
                                                        )
                                                      ]
                                                    )
                                                  : _vm.getDifference(
                                                      _vm.results
                                                        .metricRateInitial,
                                                      _vm.resultsCompare
                                                        .metricRateInitial
                                                    ) == 0
                                                  ? _c("div")
                                                  : _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "pb-1",
                                                        attrs: {
                                                          size: "medium",
                                                          color: "error"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    mdi-arrow-down-bold-circle-outline\n                  "
                                                        )
                                                      ]
                                                    )
                                              ],
                                          [
                                            !_vm.hideCounts
                                              ? _c("span", [
                                                  _c(
                                                    "span",
                                                    {
                                                      style:
                                                        "color: " +
                                                        _vm.getFontColor(
                                                          _vm.results
                                                            .metricInitial,
                                                          _vm.resultsCompare
                                                            .metricInitial
                                                        )
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "commaNumber"
                                                            )(
                                                              "" +
                                                                _vm.getAbsDifference(
                                                                  _vm.results
                                                                    .metricInitial,
                                                                  _vm
                                                                    .resultsCompare
                                                                    .metricInitial
                                                                )
                                                            )
                                                          )
                                                      )
                                                    ]
                                                  )
                                                ])
                                              : _vm.isSeconds
                                              ? _c("span", [
                                                  _c(
                                                    "span",
                                                    {
                                                      style:
                                                        "color: " +
                                                        _vm.getFontColor(
                                                          _vm.results
                                                            .metricRateInitial,
                                                          _vm.resultsCompare
                                                            .metricRateInitial
                                                        )
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            "" +
                                                              _vm.getAbsDifference(
                                                                _vm.results
                                                                  .metricRateInitial,
                                                                _vm
                                                                  .resultsCompare
                                                                  .metricRateInitial
                                                              )
                                                          ) +
                                                          _vm._s(
                                                            _vm.appendedString
                                                          )
                                                      )
                                                    ]
                                                  )
                                                ])
                                              : _vm._e()
                                          ]
                                        ],
                                        2
                                      ),
                                      [
                                        !_vm.hideCounts
                                          ? _c(
                                              "v-list-item-subtitle",
                                              {
                                                style: [
                                                  {
                                                    display: "flex",
                                                    "justify-content": "center"
                                                  }
                                                ]
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm._f("commaNumber")(
                                                        _vm.resultsCompare
                                                          .metricInitial
                                                      )
                                                    ) +
                                                    "\n                "
                                                )
                                              ]
                                            )
                                          : _vm.isSeconds
                                          ? _c(
                                              "v-list-item-subtitle",
                                              {
                                                style: [
                                                  {
                                                    display: "flex",
                                                    "justify-content": "center"
                                                  }
                                                ]
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm.resultsCompare
                                                        .metricRateInitial
                                                    ) +
                                                    _vm._s(_vm.appendedString) +
                                                    "\n                "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              _vm.iconThree && _vm.infoDescriptionThree
                                ? _c(
                                    "v-list-item",
                                    [
                                      _c(
                                        "v-list-item-subtitle",
                                        {
                                          staticClass: "pt-3",
                                          style: [{ "justify-content": "left" }]
                                        },
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: {
                                                top: "",
                                                "z-index": "15"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            {
                                                              attrs: {
                                                                left: "",
                                                                size: "30"
                                                              }
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              "\n                      " +
                                                                _vm._s(
                                                                  _vm.iconThree
                                                                ) +
                                                                "\n                    "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                480201323
                                              )
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.infoDescriptionThree
                                                  )
                                                )
                                              ])
                                            ]
                                          ),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.nameDescriptionThree)
                                            )
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item-subtitle",
                                        {
                                          style: [
                                            {
                                              display: "flex",
                                              "justify-content": "center"
                                            }
                                          ]
                                        },
                                        [
                                          !_vm.hideCounts
                                            ? [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm._f("commaNumber")(
                                                        _vm.results
                                                          .metricResends
                                                      )
                                                    ) +
                                                    " (" +
                                                    _vm._s(
                                                      _vm.results
                                                        .metricRateResends
                                                    ) +
                                                    _vm._s(_vm.appendedString) +
                                                    ")\n                "
                                                )
                                              ]
                                            : _vm.isSeconds
                                            ? [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm.results
                                                        .metricRateResends
                                                    ) +
                                                    _vm._s(_vm.appendedString) +
                                                    "\n                "
                                                )
                                              ]
                                            : [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm.results
                                                        .metricRateResends
                                                    ) +
                                                    _vm._s(_vm.appendedString) +
                                                    "\n                "
                                                )
                                              ]
                                        ],
                                        2
                                      ),
                                      _c(
                                        "v-list-item-subtitle",
                                        {
                                          style: [
                                            {
                                              display: "flex",
                                              "justify-content": "center"
                                            }
                                          ]
                                        },
                                        [
                                          !_vm.hideCounts
                                            ? [
                                                _vm.getDifference(
                                                  _vm.results.metricResends,
                                                  _vm.resultsCompare
                                                    .metricResends
                                                ) > 0
                                                  ? _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "pb-1",
                                                        attrs: {
                                                          size: "medium",
                                                          color: "green"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    mdi-arrow-up-bold-circle-outline\n                  "
                                                        )
                                                      ]
                                                    )
                                                  : _vm.getDifference(
                                                      _vm.results.metricResends,
                                                      _vm.resultsCompare
                                                        .metricResends
                                                    ) == 0
                                                  ? _c("div")
                                                  : _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "pb-1",
                                                        attrs: {
                                                          size: "medium",
                                                          color: "error"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    mdi-arrow-down-bold-circle-outline\n                  "
                                                        )
                                                      ]
                                                    )
                                              ]
                                            : [
                                                _vm.getDifference(
                                                  _vm.results.metricRateResends,
                                                  _vm.resultsCompare
                                                    .metricRateResends
                                                ) > 0
                                                  ? _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "pb-1",
                                                        attrs: {
                                                          size: "medium",
                                                          color: "green"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    mdi-arrow-up-bold-circle-outline\n                  "
                                                        )
                                                      ]
                                                    )
                                                  : _vm.getDifference(
                                                      _vm.results
                                                        .metricRateResends,
                                                      _vm.resultsCompare
                                                        .metricRateResends
                                                    ) == 0
                                                  ? _c("div")
                                                  : _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "pb-1",
                                                        attrs: {
                                                          size: "medium",
                                                          color: "error"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    mdi-arrow-down-bold-circle-outline\n                  "
                                                        )
                                                      ]
                                                    )
                                              ],
                                          [
                                            !_vm.hideCounts
                                              ? _c("span", [
                                                  _c(
                                                    "span",
                                                    {
                                                      style:
                                                        "color: " +
                                                        _vm.getFontColor(
                                                          _vm.results
                                                            .metricResends,
                                                          _vm.resultsCompare
                                                            .metricResends
                                                        )
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "commaNumber"
                                                            )(
                                                              "" +
                                                                _vm.getAbsDifference(
                                                                  _vm.results
                                                                    .metricResends,
                                                                  _vm
                                                                    .resultsCompare
                                                                    .metricResends
                                                                )
                                                            )
                                                          )
                                                      )
                                                    ]
                                                  )
                                                ])
                                              : _vm.isSeconds
                                              ? _c("span", [
                                                  _c(
                                                    "span",
                                                    {
                                                      style:
                                                        "color: " +
                                                        _vm.getFontColor(
                                                          _vm.results
                                                            .metricRateResends,
                                                          _vm.resultsCompare
                                                            .metricRateResends
                                                        )
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            "" +
                                                              _vm.getAbsDifference(
                                                                _vm.results
                                                                  .metricRateResends,
                                                                _vm
                                                                  .resultsCompare
                                                                  .metricRateResends
                                                              )
                                                          ) +
                                                          _vm._s(
                                                            _vm.appendedString
                                                          )
                                                      )
                                                    ]
                                                  )
                                                ])
                                              : _vm._e()
                                          ]
                                        ],
                                        2
                                      ),
                                      [
                                        !_vm.hideCounts
                                          ? _c(
                                              "v-list-item-subtitle",
                                              {
                                                style: [
                                                  {
                                                    display: "flex",
                                                    "justify-content": "center"
                                                  }
                                                ]
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm._f("commaNumber")(
                                                        _vm.resultsCompare
                                                          .metricResends
                                                      )
                                                    ) +
                                                    "\n                "
                                                )
                                              ]
                                            )
                                          : _vm.isSeconds
                                          ? _c(
                                              "v-list-item-subtitle",
                                              {
                                                style: [
                                                  {
                                                    display: "flex",
                                                    "justify-content": "center"
                                                  }
                                                ]
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm.resultsCompare
                                                        .metricRateResends
                                                    ) +
                                                    _vm._s(_vm.appendedString) +
                                                    "\n                "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    ],
                                    2
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "flex-row-reverse pt-1" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("viewDetails", _vm.name)
                        }
                      }
                    },
                    [_vm._v("\n        View\n      ")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm.resultsLoading
        ? _c("v-skeleton-loader", { attrs: { type: "image" } })
        : _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "text-h5 mb-2 pb-0" },
                [
                  _vm._v("\n      " + _vm._s(_vm.displayName) + "\n      "),
                  _c("v-spacer"),
                  _vm.infoDescription
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { "z-index": "15", top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-icon",
                                      _vm._g({ attrs: { color: "info" } }, on),
                                      [
                                        _vm._v(
                                          "\n            mdi-information\n          "
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1838283674
                          )
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.infoDescription))])]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c("v-card-text", [_vm._v("\n      No data\n    ")])
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }