<template>
  <div>
    <v-card
      v-if="!hasAccess"
    >
      <v-card-title class="text-h5 mb-2 pb-0">
        {{ displayName }}
        <v-spacer />
        <v-tooltip
          v-if="infoDescription"
          top
          z-index="15"
        >
          <template v-slot:activator="{ on }">
            <v-icon
              color="info"
              v-on="on"
            >
              mdi-information
            </v-icon>
          </template>
          <span>{{ infoDescription }}</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        You do not have "Reporting - KPI Widgets permissions"
      </v-card-text>
    </v-card>
    <v-card
      v-else-if="dates.length === 2 && !resultsLoading"
    >
      <v-card-title class="text-h5 mb-2 pb-0">
        {{ displayName }}
        <v-spacer />
        <v-tooltip
          v-if="infoDescription"
          z-index="15"
          top
        >
          <template v-slot:activator="{ on }">
            <v-icon
              color="info"
              v-on="on"
            >
              mdi-information
            </v-icon>
          </template>
          <span>{{ infoDescription }}</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text class="pb-0">
        <v-row>
          <v-col
            cols="auto"
            class="pr-0"
          >
            <v-progress-circular
              :rotate="-90"
              :size="100"
              :width="15"
              :value="results.metricRateTotals"
              color="#98EB108A"
            >
              <v-icon
                size="52"
                color="black"
              >
                {{ icon }}
              </v-icon>
            </v-progress-circular>
          </v-col>
          <v-col>
            <v-list>
              <v-list-item>
                <v-list-item-title />
                <v-list-item-title
                  class="text-wrap"
                  :style="[{ 'display': 'flex', 'justify-content': 'center', 'font-weight': 'bold'}]"
                >
                  {{ dateRangeText(dates) }}
                </v-list-item-title>
                <v-list-item-title :style="[{ 'display': 'flex', 'justify-content': 'center', 'font-weight': 'bold'}]">
                  VS
                </v-list-item-title>
                <v-list-item-title
                  class="text-wrap"
                  :style="[{ 'display': 'flex', 'justify-content': 'center', 'font-weight': 'bold'}]"
                >
                  {{ dateRangeText(comparisonDates) }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title />
                <v-list-item-title
                  v-if="!hideCounts"
                  :style="[{ 'display': 'flex', 'justify-content': 'center'}]"
                >
                  {{ results.metricTotals | commaNumber }} ({{ results.metricRateTotals }} {{ appendedString }})
                </v-list-item-title>
                <v-list-item-title
                  v-else-if="isSeconds"
                  :style="[{ 'display': 'flex', 'justify-content': 'center'}]"
                >
                  {{ results.metricRateTotals }}{{ appendedString }}
                </v-list-item-title>
                <v-list-item-title
                  v-else
                  :style="[{ 'display': 'flex', 'justify-content': 'center'}]"
                >
                  {{ results.metricRateTotals }}{{ appendedString }}
                </v-list-item-title>
                <v-list-item-title :style="[{ 'display': 'flex', 'justify-content': 'center'}]">
                  <template v-if="!hideCounts">
                    <v-icon
                      v-if="getDifference(results.metricTotals, resultsCompare.metricTotals) > 0"
                      size="medium"
                      color="green"
                    >
                      mdi-arrow-up-bold-circle-outline
                    </v-icon>
                    <div v-else-if="getDifference(results.metricTotals, resultsCompare.metricTotals) == 0" />
                    <v-icon
                      v-else
                      size="medium"
                      color="error"
                    >
                      mdi-arrow-down-bold-circle-outline
                    </v-icon>
                  </template>
                  <template v-else>
                    <v-icon
                      v-if="getDifference(results.metricRateTotals, resultsCompare.metricRateTotals) > 0"
                      size="medium"
                      color="green"
                    >
                      mdi-arrow-up-bold-circle-outline
                    </v-icon>
                    <div v-else-if="getDifference(results.metricRateTotals, resultsCompare.metricRateTotals) == 0" />
                    <v-icon
                      v-else
                      size="medium"
                      color="error"
                    >
                      mdi-arrow-down-bold-circle-outline
                    </v-icon>
                  </template>
                  <template>
                    <span v-if="!hideCounts">
                      <span :style="`color: ${getFontColor(results.metricTotals, resultsCompare.metricTotals)}`"> {{ `${getAbsDifference(results.metricTotals, resultsCompare.metricTotals)}` | commaNumber }}</span>
                    </span>
                    <span v-else-if="isSeconds">
                      <span :style="`color: ${getFontColor(results.metricRateTotals, resultsCompare.metricRateTotals)}`"> {{ `${getAbsDifference(results.metricRateTotals, resultsCompare.metricRateTotals)}` }}{{ appendedString }}</span>
                    </span>
                    <span v-else>
                      <span :style="`color: ${getFontColor(results.metricRateTotals, resultsCompare.metricRateTotals)}`"> {{ `${getAbsDifference(results.metricRateTotals, resultsCompare.metricRateTotals)}` }}{{ appendedString }}</span>
                    </span>
                  </template>
                </v-list-item-title>
                <template>
                  <v-list-item-title
                    v-if="!hideCounts"
                    :style="[{ 'display': 'flex', 'justify-content': 'center'}]"
                  >
                    {{ resultsCompare.metricTotals | commaNumber }}
                  </v-list-item-title>
                  <v-list-item-title
                    v-else-if="isSeconds"
                    :style="[{ 'display': 'flex', 'justify-content': 'center'}]"
                  >
                    {{ resultsCompare.metricRateTotals }}{{ appendedString }}
                  </v-list-item-title>
                  <v-list-item-title
                    v-else
                    :style="[{ 'display': 'flex', 'justify-content': 'center'}]"
                  >
                    {{ resultsCompare.metricRateTotals }}{{ appendedString }}
                  </v-list-item-title>
                </template>
              </v-list-item>
              <v-list-item v-if="iconTwo && infoDescriptionTwo">
                <v-list-item-subtitle
                  :style="[{'justify-content': 'left'}]"
                >
                  <v-tooltip
                    top
                    z-index="15"
                  >
                    <template v-slot:activator="{ on }">
                      <v-icon
                        left
                        size="30"
                        v-on="on"
                      >
                        {{ iconTwo }}
                      </v-icon>
                    </template>
                    <span>{{ infoDescriptionTwo }}</span>
                  </v-tooltip>
                  <span>{{ nameDescriptionTwo }}</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle :style="[{ 'display': 'flex', 'justify-content': 'center'}]">
                  <template v-if="!hideCounts">
                    {{ results.metricInitial | commaNumber }} ({{ results.metricRateInitial }}{{ appendedString }})
                  </template>
                  <template v-else-if="isSeconds">
                    {{ results.metricRateInitial }}{{ appendedString }}
                  </template>
                  <template v-else>
                    {{ results.metricRateInitial }}{{ appendedString }}
                  </template>
                </v-list-item-subtitle>
                <v-list-item-subtitle :style="[{ 'display': 'flex', 'justify-content': 'center'}]">
                  <template v-if="!hideCounts">
                    <v-icon
                      v-if="getDifference(results.metricInitial, resultsCompare.metricInitial) > 0"
                      class="pb-1"
                      size="medium"
                      color="green"
                    >
                      mdi-arrow-up-bold-circle-outline
                    </v-icon>
                    <div v-else-if="getDifference(results.metricInitial, resultsCompare.metricInitial) == 0" />
                    <v-icon
                      v-else
                      class="pb-1"
                      size="medium"
                      color="error"
                    >
                      mdi-arrow-down-bold-circle-outline
                    </v-icon>
                  </template>
                  <template v-else>
                    <v-icon
                      v-if="getDifference(results.metricRateInitial, resultsCompare.metricRateInitial) > 0"
                      class="pb-1"
                      size="medium"
                      color="green"
                    >
                      mdi-arrow-up-bold-circle-outline
                    </v-icon>
                    <div v-else-if="getDifference(results.metricRateInitial, resultsCompare.metricRateInitial) == 0" />
                    <v-icon
                      v-else
                      class="pb-1"
                      size="medium"
                      color="error"
                    >
                      mdi-arrow-down-bold-circle-outline
                    </v-icon>
                  </template>
                  <template>
                    <span v-if="!hideCounts">
                      <span :style="`color: ${getFontColor(results.metricInitial, resultsCompare.metricInitial)}`"> {{ `${getAbsDifference(results.metricInitial, resultsCompare.metricInitial)}` | commaNumber }}</span>
                    </span>
                    <span v-else-if="isSeconds">
                      <span :style="`color: ${getFontColor(results.metricRateInitial, resultsCompare.metricRateInitial)}`"> {{ `${getAbsDifference(results.metricRateInitial, resultsCompare.metricRateInitial)}` }}{{ appendedString }}</span>
                    </span>
                  </template>
                </v-list-item-subtitle>
                <template>
                  <v-list-item-subtitle
                    v-if="!hideCounts"
                    :style="[{ 'display': 'flex', 'justify-content': 'center'}]"
                  >
                    {{ resultsCompare.metricInitial | commaNumber }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    v-else-if="isSeconds"
                    :style="[{ 'display': 'flex', 'justify-content': 'center'}]"
                  >
                    {{ resultsCompare.metricRateInitial }}{{ appendedString }}
                  </v-list-item-subtitle>
                </template>
              </v-list-item>
              <v-list-item v-if="iconThree && infoDescriptionThree">
                <v-list-item-subtitle
                  class="pt-3"
                  :style="[{'justify-content': 'left'}]"
                >
                  <v-tooltip
                    top
                    z-index="15"
                  >
                    <template v-slot:activator="{ on }">
                      <v-icon
                        left
                        size="30"
                        v-on="on"
                      >
                        {{ iconThree }}
                      </v-icon>
                    </template>
                    <span>{{ infoDescriptionThree }}</span>
                  </v-tooltip>
                  <span>{{ nameDescriptionThree }}</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle :style="[{ 'display': 'flex', 'justify-content': 'center'}]">
                  <template v-if="!hideCounts">
                    {{ results.metricResends | commaNumber }} ({{ results.metricRateResends }}{{ appendedString }})
                  </template>
                  <template v-else-if="isSeconds">
                    {{ results.metricRateResends }}{{ appendedString }}
                  </template>
                  <template v-else>
                    {{ results.metricRateResends }}{{ appendedString }}
                  </template>
                </v-list-item-subtitle>
                <v-list-item-subtitle :style="[{ 'display': 'flex', 'justify-content': 'center'}]">
                  <template v-if="!hideCounts">
                    <v-icon
                      v-if="getDifference(results.metricResends, resultsCompare.metricResends) > 0"
                      class="pb-1"
                      size="medium"
                      color="green"
                    >
                      mdi-arrow-up-bold-circle-outline
                    </v-icon>
                    <div v-else-if="getDifference(results.metricResends, resultsCompare.metricResends) == 0" />
                    <v-icon
                      v-else
                      class="pb-1"
                      size="medium"
                      color="error"
                    >
                      mdi-arrow-down-bold-circle-outline
                    </v-icon>
                  </template>
                  <template v-else>
                    <v-icon
                      v-if="getDifference(results.metricRateResends, resultsCompare.metricRateResends) > 0"
                      class="pb-1"
                      size="medium"
                      color="green"
                    >
                      mdi-arrow-up-bold-circle-outline
                    </v-icon>
                    <div v-else-if="getDifference(results.metricRateResends, resultsCompare.metricRateResends) == 0" />
                    <v-icon
                      v-else
                      class="pb-1"
                      size="medium"
                      color="error"
                    >
                      mdi-arrow-down-bold-circle-outline
                    </v-icon>
                  </template>
                  <template>
                    <span v-if="!hideCounts">
                      <span :style="`color: ${getFontColor(results.metricResends, resultsCompare.metricResends)}`"> {{ `${getAbsDifference(results.metricResends, resultsCompare.metricResends)}` | commaNumber }}</span>
                    </span>
                    <span v-else-if="isSeconds">
                      <span :style="`color: ${getFontColor(results.metricRateResends, resultsCompare.metricRateResends)}`"> {{ `${getAbsDifference(results.metricRateResends, resultsCompare.metricRateResends)}` }}{{ appendedString }}</span>
                    </span>
                  </template>
                </v-list-item-subtitle>
                <template>
                  <v-list-item-subtitle
                    v-if="!hideCounts"
                    :style="[{ 'display': 'flex', 'justify-content': 'center'}]"
                  >
                    {{ resultsCompare.metricResends | commaNumber }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    v-else-if="isSeconds"
                    :style="[{ 'display': 'flex', 'justify-content': 'center'}]"
                  >
                    {{ resultsCompare.metricRateResends }}{{ appendedString }}
                  </v-list-item-subtitle>
                </template>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="flex-row-reverse pt-1">
        <v-btn
          color="primary"
          @click="$emit('viewDetails', name)"
        >
          View
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-skeleton-loader
      v-else-if="resultsLoading"
      type="image"
    />
    <v-card v-else>
      <v-card-title class="text-h5 mb-2 pb-0">
        {{ displayName }}
        <v-spacer />
        <v-tooltip
          v-if="infoDescription"
          z-index="15"
          top
        >
          <template v-slot:activator="{ on }">
            <v-icon
              color="info"
              v-on="on"
            >
              mdi-information
            </v-icon>
          </template>
          <span>{{ infoDescription }}</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        No data
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {
  inputDateFormat,
  displayMonthFormat
} from '@/shared/constants'
import mutationTypes from '@/store/mutation-types'
import { mailingService } from '@/shared/services'
import claims from '@/shared/models/auth/claims'
import moment from 'moment'

export default {
  name: 'ComparisonBasicMetricsWidget',

  props: {
    displayName: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    mailingServiceMethod: {
      type: String,
      default: 'getReportMetricWidget'
    },
    hideCounts: {
      type: Boolean,
      default: false
    },
    appendedString: {
      type: String,
      default: '%'
    },
    dates: {
      type: Array,
      default: () => []
    },
    comparisonDates: {
      type: Array,
      default: () => []
    },
    isSeconds: {
      type: Boolean,
      default: true
    },
    icon: {
      type: String,
      default: 'mdi-email'
    },
    iconTwo: {
      type: String,
      default: ''
    },
    iconThree: {
      type: String,
      default: ''
    },
    infoDescription: {
      type: String,
      default: ''
    },
    infoDescriptionTwo: {
      type: String,
      default: ''
    },
    infoDescriptionThree: {
      type: String,
      default: ''
    },
    nameDescriptionTwo: {
      type: String,
      default: ''
    },
    nameDescriptionThree: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      resultsLoading: false,
      results: {},
      resultsCompare: {},
      unsubscribe: null,
      green: '#4CAF50',
      red: '#F44336'
    }
  },

  computed: {
    hasAccess () {
      return this.$store.getters['auth/hasClaimKV'](claims.R_KPIW)
    }
  },

  watch: {
    dates (newValue, oldValue) {
      if (newValue.length === 2) {
        this.getMetricData()
      }
    },
    comparisonDates (newValue, oldValue) {
      if (newValue.length === 2) {
        this.getMetricData()
      }
    }
  },

  created () {
    this.getMetricData()
  },

  mounted () {
    this.unsubscribe = this.$store.subscribe(mutation => {
      let listeners = [
        mutationTypes.SET_VIEW_AS_CUSTOMER
      ]
      if (listeners.includes(mutation.type)) {
        this.getMetricData()
      }
    })
  },

  destroyed () {
    this.unsubscribe()
  },

  methods: {
    getAbsDifference (metric, comparisonMetric) {
      return Math.abs(this.getDifference(metric, comparisonMetric))
    },
    getFontColor (metric, comparisonMetric) {
      if (this.getDifference(metric, comparisonMetric) === 0) return '#000000'
      return this.getDifference(metric, comparisonMetric) > 0 ? this.green : this.getDifference(metric, comparisonMetric) < 0 ? '#ed1a3b' : '#000000'
    },
    getDifference (metric, comparisonMetric) {
      var dif = metric - comparisonMetric
      return dif.toFixed(2)
    },
    dateRangeText (dates) {
      if (!dates || dates.length !== 2) return ''
      if (moment(dates[0]).isSame(moment(dates[1]), 'month')) return moment(dates[0]).format(displayMonthFormat)
      return dates.map(x => moment(x).format(displayMonthFormat)).join(' - ')
    },
    async getMetricData () {
      if (this.dates.length !== 2 || this.comparisonDates.length !== 2 || this.$store.getters['isSimulatedMMS']()) return
      var _this = this
      this.resultsLoading = true
      await mailingService[this.mailingServiceMethod]({
        customerNowwId: this.$store.getters['simulatedCustomerNowwId'](),
        metricName: this.name,
        startDate: moment(this.dates[0]).startOf('month').format(inputDateFormat),
        endDate: moment(this.dates[1]).endOf('month').format(inputDateFormat)
      }).then(function (resp) {
        if (resp.errorResponse) {
          _this.$store.commit('setGlobalSnackbar', {
            message: resp.errorResponse,
            color: 'error'
          })
        }
        _this.results = resp
      })
      await mailingService[this.mailingServiceMethod]({
        customerNowwId: this.$store.getters['simulatedCustomerNowwId'](),
        metricName: this.name,
        startDate: moment(this.comparisonDates[0]).startOf('month').format(inputDateFormat),
        endDate: moment(this.comparisonDates[1]).endOf('month').format(inputDateFormat)
      }).then(function (resp) {
        if (resp.errorResponse) {
          _this.$store.commit('setGlobalSnackbar', {
            message: resp.errorResponse,
            color: 'error'
          })
        }
        _this.resultsCompare = resp
      }).finally(() => {
        this.resultsLoading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
